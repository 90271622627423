import React from "react"
import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import RichText from "../../reusable/render/RichText"
import {useTranslation} from "next-i18next"

type Props = {
	className?: string,
	blockTilesHowItWorks?: any
    hasOptionalText?: boolean
}

const HowItWorks = ({blockTilesHowItWorks, className = "", hasOptionalText}: Props): React.ReactElement => {
	const {title, tiles} = blockTilesHowItWorks.fields
	const {t} = useTranslation(["common", "konzept"])

	return (
		<section className={className}>
			<div className="container">
				<div className="d-block d-md-none">
					<Typography variant={"heading2"}
						semanticTag="h1"
						className={""}
						style={{fontSize: 28, marginBottom: 35}}>{title}</Typography>
					<div className="row text-lg-center">

						{tiles.map(({fields}, index) => {
							const {description, title, icon} = fields

							return (
								<div key={title}
									className="col-12 col-lg-4">
									<div className="row justify-content-center position-relative">
										<>
											<div className="col-2 d-flex flex-column align-items-center position-relative text-center">
												<Image className="detail-img"
													src={icon}
													alt="orange circle with hand pointing"
													width={40}
													height={40}
												/>
												<div className="dots-wrapper d-flex align-items-end position-absolute"
													style={{bottom: 24}}>
													{index + 1 !== tiles.length && <div className="solid-lines"/>}
												</div>
											</div>
										</>
										<div className="col-10 col-lg-12"
											style={{
												marginTop: "5px"
											}}>
											<Typography variant="bodyLgBlack"
												semanticTag="h3"
												style={{
													fontSize: 20}}
												className="mb-2">
												{title}
											</Typography>
											<RichText content={description.content}/>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<div className="d-none d-md-block">
					<Typography variant={"heading2Black"}
						semanticTag="h1"
						className={"text-center mb-5"}>{title}</Typography>
					<div className="row gx-5 d-flex justify-content-between">
						{tiles.map(({fields}, index) => {
							const {description, title, icon, lineWidth} = fields

							return (
								<div key={index}
									className="position-relative col-4 pe-0 d-flex justify-content-center">
									<div>
										<Image src={icon}
											height={40}
											width={40}/>
									</div>
									<div className="mt-2 ms-3">
										<div className="d-flex align-items-center">
											<Typography variant="bodyLgBlack"
												semanticTag="h3"
												style={{
													fontSize: 20
												}}
												className="mb-2 pe-4 col-auto">
												{title}
											</Typography>
											{ index !== (tiles.length - 1) &&
											<div className="position-absolute col-auto"
												style={{height: 1, right: -20, border: "1px solid #212529", width: lineWidth ? lineWidth : "30%"}}/>
											}
										</div>
										<div style={{maxWidth: 285}}>
											<div className="how-it-works-paragraph">
												<RichText content={description.content}/>
											</div>

											{ index === 0 && hasOptionalText &&
											<div className="d-flex mt-2 align-items-start">
												<div className="pt-1 d-flex align-items-start"
													style={{height: 40, width: 26}}>
													<Image src={"/assets/icons/right-arrow-curving-up.svg"}
														height={20}
														width={20}/>
												</div>

												<Typography className="ms-2"
													variant="bodyLg">{t("Optional: Add accessories and insurance.")}</Typography>
											</div>
											}
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</section>
	)
}

export default HowItWorks

