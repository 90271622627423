export const staticDataHowItWorks = {

	de: {
		fields: {
			tiles: [
				{
					fields: {
						description: {
							content: [
								{
									content: [
										{value: "Bestelle dein E-Bike, Velo und entscheide dich für eine flexible Laufzeit der monatlichen Zahlungen zwischen 12 und 48 Monaten mit 0% Zins."}
									], nodeType: "paragraph"
								}
							]
						},
						icon: "/assets/icons/howItWorks/bike-icon-left.png",
						title: "Beantragen"
					}
				},
				{
					fields: {
						description: {
							content: [
								{
									content: [
										{value: "Nach erfolgreicher Prüfung senden wir dir den Finanzierungsvertrag zu. Der Prozess bleibt bis zur Unterzeichnung unverbindlich."}
									], nodeType: "paragraph"
								}
							]
						},
						icon: "/assets/icons/howItWorks/hand-writing.png",
						title: "Unterschreiben"
					}
				},
				{
					fields: {
						description: {
							content: [
								{
									content: [
										{value: "Dein E-Bike oder Velo wird für dich montiert und direkt zu dir nach Hause geliefert. Nach kurzem Überprüfen kannst du sofort losfahren! Die Bezahlung erfolgt bequem in monatlichen Raten über unseren Partner."}
									], nodeType: "paragraph"
								}
							]
						},
						icon: "/assets/icons/howItWorks/rocket-up.png",
						title: "Fahren"
					}
				}

			],
			title: "Wie funktioniert MyBikePlan?"
		}
	},
	fr: {
		fields: {
			tiles: [
				{
					fields: {
						description: {
							content: [
								{
									content: [
										{value: "Commande ton e-bike, ton vélo et opte pour une durée flexible des paiements mensuels entre 12 et 48 mois avec un taux d'intérêt de 0%."}
									], nodeType: "paragraph"
								}
							]
						},
						icon: "/assets/icons/howItWorks/bike-icon-left.png",
						title: "Demander"
					}
				},
				{
					fields: {
						description: {
							content: [
								{
									content: [
										{value: "Après une vérification réussie, nous t'envoyons le contrat de financement. Le processus reste sans engagement jusqu'à la signature."}
									], nodeType: "paragraph"
								}
							]
						},
						icon: "/assets/icons/howItWorks/hand-writing.png",
						title: "Signer"
					}
				},
				{
					fields: {
						description: {
							content: [
								{
									content: [
										{value: "Ton vélo électrique ou ton vélo est monté pour toi et livré directement à ton domicile. Après un bref contrôle, tu peux démarrer immédiatement ! Le paiement s'effectue confortablement par mensualités via notre partenaire."}
									], nodeType: "paragraph"
								}
							]
						},
						icon: "/assets/icons/howItWorks/rocket-up.png",
						title: "Conduite"
					}
				}

			],
			title: "Comment fonctionne MyBikePlan?"
		}
	}

}

// For Suggestions Page
export const staticDataHowSuggestionWorks = {

	de: {
		fields: {
			tiles: [
				{
					fields: {
						description: {
							content: [
								{
									content: [
										{value: "Fülle den Fragebogen aus, damit wir eine Vorstellung von Deinem perfekten Traumbike erhalten."}
									], nodeType: "paragraph"
								}
							]
						},
						icon: "/assets/icons/howItWorks/handwriting.svg",
						title: "Fragen beantworten",
						lineWidth: "30%"
					}
				},
				{
					fields: {
						description: {
							content: [
								{
									content: [
										{value: "Unsere Veloprofis überprüfen Deine Angaben und suchen persönliche Empfehlungen für Dich heraus."}
									], nodeType: "paragraph"
								}
							]
						},
						icon: "/assets/icons/howItWorks/thinking-emoji.svg",
						title: "Überprüfen",
						lineWidth: "45%"
					}
				},
				{
					fields: {
						description: {
							content: [
								{
									content: [
										{value: "Ihr Fahrrad wird für Sie zusammengebaut und direkt zu Ihnen nach Hause geliefert. Dann können Sie losfahren!"}
									], nodeType: "paragraph"
								}
							]
						},
						icon: "/assets/icons/howItWorks/celebrate.svg",
						title: "Empfehlungen erhalten",
						lineWidth: ""
					}
				}

			],
			title: "Wie funktioniert das Ganze?"
		}
	},
	fr: {
		fields: {
			tiles: [
				{
					fields: {
						description: {
							content: [
								{
									content: [
										{value: "Remplis le questionnaire pour que l'on puisse se faire une idée du vélo de tes rêves."}
									], nodeType: "paragraph"
								}
							]
						},
						icon: "/assets/icons/howItWorks/handwriting.svg",
						title: "Répond aux questions",
						lineWidth: "25%"
					}
				},
				{
					fields: {
						description: {
							content: [
								{
									content: [
										{value: "Nos professionnels du vélo vérifient tes réponses et te font des recommandations personnalisées."}
									], nodeType: "paragraph"
								}
							]
						},
						icon: "/assets/icons/howItWorks/thinking-emoji.svg",
						title: "Vérification",
						lineWidth: "35%"
					}
				},
				{
					fields: {
						description: {
							content: [
								{
									content: [
										{value: "Ton vélo sera assemblé pour toi et livré directement à ton domicile. Tu pourras alors commencer à rouler !"}
									], nodeType: "paragraph"
								}
							]
						},
						icon: "/assets/icons/howItWorks/celebrate.svg",
						title: "Réception des recommandations",
						lineWidth: ""
					}
				}

			],
			title: "Comment ça fonctionne ?"
		}
	}

}

